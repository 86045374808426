.container {
  background-color: #f5f5f5; /* Light grey, adjust for branding */
  padding: 20px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  max-height: 100px; /* Adjust based on your preference */
  width: auto; /* Maintain aspect ratio */
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.nav-links li {
  margin-left: 30px; /* Increased spacing between nav items */
}

.nav-links a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-links a:hover {
  color: #007BFF; /* Highlight color on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 767px) {
  .logo img {
    max-height: 50px; /* Smaller logo on small screens */
  }

  .nav-links li {
    margin-left: 15px; /* Less spacing between nav items on small screens */
  }

  .nav-links a {
    font-size: 1.54rem; /* Updated font size for better readability on mobile */
    padding: 5px 5px  
  }

  .row {
    flex-direction: column;
    text-align: center;
  }

  .nav-links {
    margin-top: -40px; /* Add some space between logo and nav links */
    flex-direction: column; /* Stack nav items vertically */
    margin-bottom: -25px;
  }

  .nav-links li {
    margin: 10px 0; /* Adjust spacing for vertical layout */
  }
}
