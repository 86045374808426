/* HomePage.css */
.banner h1 {
  margin-top: 1rem; /* Adds a bit more space above the heading */
}

.about-us, .cta, .home-footer {
  margin-top: 2rem; /* Adds spacing between sections for better readability */
}

.home-footer {
  background-color: #333; /* Dark footer */
  color: #fff;
  padding: 1rem 0;
}

.home-footer a {
  color: #FFD700; /* Gold color for links */
}


/* General Button Style (if not already defined) */
.button {
  display: inline-block;
  background-color: #007BFF; /* Adjust the color to fit your design */
  color: white;
  padding: 10px 20px; /* Adjust padding to your preference */
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

/* Responsive adjustments for the CTA section */
@media (max-width: 767px) {
  .cta .button {
    padding: 15px 30px; /* Larger padding for easier tapping on mobile */
    font-size: 1rem; /* Adjust font size for readability */
    width: 100%; /* Optional: Makes the button full width */
    line-height: 35px; /* Adjust line height to center the text vertically */
    height: 36px; /* Example fixed height, adjust as needed */
    padding: 0 30px; /* Remove top and bottom padding if the height is fixed */
    box-sizing: border-box; /* Ensures padding doesn't add to the width */
  }

  /* Adjust the CTA section if needed */
  .cta {
    text-align: center; /* Center-align the CTA button for emphasis */
    padding: 20px 10px; /* Add some padding around the CTA for spacing */
  }

  /* Ensure the row doesn't constrain the CTA sizing */
  .row {
    flex-direction: column; /* Stack elements vertically on small screens */
    padding: 0; /* Remove any default padding/margin that may affect layout */
  }
}