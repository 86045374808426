.boiling-text {
  display: inline-block;
  font-family: 'Lobster', cursive; /* Updated font family */
}

.boiling-char {
  display: inline-block;
  animation: boiling 0.3s infinite alternate, spicy-color 1s infinite alternate;
}

@keyframes boiling {
  0%, 100% {
    transform: translate(0);
  }
  25% {
    transform: translate(-1px, -2px);
  }
  50% {
    transform: translate(1px, 2px);
  }
  75% {
    transform: translate(0, -1px);
  }
}

/* Color cycle for "spicy" effect */
@keyframes spicy-color {
  0%, 100% {
    color: #F00;
  }
  50% {
    color: #FF5733;
  }
}
