.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .menu-section-title {
    font-size: 1 rem;
    margin-bottom: 20px;
  }
  
  .menu-column {
    padding: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .menu-column:last-child {
    border-bottom: none;
  }
  
  .menu-section-name {
    font-size: 1 rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .menu-sub-section-name {
    font-size: 1 rem;
    font-weight: 580;
    margin-bottom: 10px;
    font-style: italic;
  }

  .menu-item-name {
    font-size: 2 rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .menu-item-description {
    font-size: 2rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  .menu-item-price {
    font-size: 2.1rem;
    color: #333;
  }
  
  .menu-subitem-name {
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 2px;
  }
  