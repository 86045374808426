.copyright {
    text-align: center;
    font-size: 0.9rem;
    color: #999;
  }
  
  .social-links {
    text-align: center;
    margin-top: 10px;
  }
  
  .social-links i {
    font-size: 1.2rem;
    margin-right: 10px;
    color: #333;
  }
  