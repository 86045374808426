/* Import Skeleton CSS (if using): */
@import url('https://cdnjs.cloudflare.com/ajax/libs/skeleton/2.0.4/skeleton.min.css');

/* General styles for the HTML document: */
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

/* Styles for your main content container: */
.container {
  width: 80%; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

/* Add more specific styles for your website elements here */
