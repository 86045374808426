/* General Container Styles */
.container {
  max-width: 600px; /* Adjust as needed */
  margin: auto;
  padding: 20px;
}

.section-title {
  text-align: center; /* Center-align section titles */
}

/* Row and Column Layout */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Adjusting columns for a responsive design */
.six.columns {
  width: 100%; /* Full width on smaller screens */
  text-align: center; /* Center-align text */
  padding: 15px; /* Add padding for spacing */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 767px) {
  .six.columns {
    margin-top: 20px; /* Space between stacked columns */
  }
  
  .six.columns:first-child {
    margin-top: 0; /* Align first column nicely at the top */
  }
}

/* Styling for Links and Buttons */
a {
  color: #007BFF; /* Link color */
  text-decoration: none; /* No underline */
}

a:hover {
  color: #0056b3; /* Link hover color */
}

.button-primary {
  background-color: #007BFF; /* Button background color */
  color: white; /* Button text color */
  padding: 10px 20px; /* Button padding */
  border-radius: 5px; /* Rounded corners for the button */
  text-decoration: none; /* No underline */
  display: inline-block; /* Necessary for alignment */
}

.button-primary:hover {
  background-color: #0056b3; /* Button hover background color */
}

/* Form Element Styles */
label {
  display: block;
  margin-top: 20px; /* Spacing above labels */
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%; /* Full width for form elements */
  padding: 8px; /* Padding inside form elements */
  margin-top: 5px; /* Spacing above form elements */
  margin-bottom: 20px; /* Spacing below form elements */
  box-sizing: border-box; /* Include padding and border in element size */
}

button[type="submit"] {
  background-color: #007BFF; /* Submit button background color */
  color: white; /* Submit button text color */
  padding: 10px 20px; /* Submit button padding */
  border: none; /* No border */
  cursor: pointer; /* Cursor pointer on hover */
}

button[type="submit"]:hover {
  background-color: #0056b3; /* Submit button hover color */
}
